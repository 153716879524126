jQuery.event.special.touchstart = {
  setup: function (_, ns, handle) {
    if (ns.includes("noPreventDefault")) {
      this.addEventListener("touchstart", handle, { passive: false });
    } else {
      this.addEventListener("touchstart", handle, { passive: true });
    }
  }
};

jQuery.event.special.touchmove = {
  setup: function (_, ns, handle) {
    if (ns.includes("noPreventDefault")) {
      this.addEventListener("touchmove", handle, { passive: false });
    } else {
      this.addEventListener("touchmove", handle, { passive: true });
    }
  }
};

AOS.init();

var buttons = document.querySelectorAll('.btn');
buttons.forEach(function (button) {
  button.innerHTML = '<span>' + button.innerHTML + '</span>';
});

$("a[href*='#']").on('click', function (event) {
  var targetId = this.hash.substring(1);
  var target = $('#' + targetId);
  if (target.length) {
    event.preventDefault();
    if (target[0].id) {
      var targetPosition = target.offset().top;
      var headerHeight = 124;
      targetPosition -= headerHeight;
      $('html, body').animate({
        scrollTop: targetPosition
      }, 800);
    } else {
      window.location.href = $(this).attr('href');
    }
  }
});

$(window).on('load', function () {
  if (window.location.hash) {
    var targetId = window.location.hash.substring(1);
    var target = $('#' + targetId);
    if (target.length) {
      if (target[0].id) {
        var targetPosition = target.offset().top;
        var headerHeight = 124;
        targetPosition -= headerHeight;
        $('html, body').animate({
          scrollTop: targetPosition
        }, 800);
      } else {
        window.location.href = targetId;
      }
    }
  }
});

/**** Main menu ****/

var menu = $('.navbar');
var offsetTop = menu.offset().top;

$(window).on('scroll', function () {
  var scrollTop = $(this).scrollTop();

  if (scrollTop > 0) {
    menu.addClass('scroll');
  } else {
    menu.removeClass('scroll');
  }
});

$(window).on('load', function () {
  $(window).trigger('scroll');
});

if ($(window).scrollTop() > offsetTop) {
  menu.addClass('scroll');
}


$(".navbar-icon").on('click', function () {
  $(this).toggleClass("active");
  $(".navbar-menu nav").toggleClass("active");
  $(".navbar").toggleClass("active");
  $("body").toggleClass("active");
  $("a.level1").toggleClass("opened");

});

$("a.level1").on('click', function () {
  $(".navbar-icon").removeClass("active");
  $(".navbar-menu nav").removeClass("active");
  $(".navbar").removeClass("active");
  $("body").removeClass("active");
});

/**** Main menu end ****/

/**** Cursor ****/

var $cursor = $('#cursor');
var mouse = { x: 300, y: 300 };
var pos = { x: 0, y: 0 };
var speed = 0.1;

var updatePosition = function () {
  pos.x += (mouse.x - pos.x) * speed;
  pos.y += (mouse.y - pos.y) * speed;

  $cursor.css('transform', 'translate3d(' + pos.x + 'px, ' + pos.y + 'px, 0)');
};

var updateCoordinates = function (e) {
  mouse.x = e.clientX;
  mouse.y = e.clientY;
}

$(window).on('mousemove', updateCoordinates);

function loop() {
  updatePosition();
  requestAnimationFrame(loop);
}

requestAnimationFrame(loop);

$('a, button').on('mouseenter', function () {
  $cursor.addClass('cursor-expand');
});

$('a, button').on('mouseleave', function () {
  $cursor.removeClass('cursor-expand');
});

/**** Cursor end ****/

/**** Reveal animation ****/

$(window).on('load', function () {
  if (!sessionStorage.getItem('animationPlayed')) {
    var diameter = Math.sqrt(Math.pow($(window).width(), 2) + Math.pow($(window).height(), 2));
    $('#circle-reveal').css({
      width: diameter,
      height: diameter
    }).addClass('expand');

    setTimeout(function () {
      $('#circle-reveal').addClass('hide');
      $('#white-cover').addClass('hide');

      setTimeout(function () {
        $('#circle-reveal').css('display', 'none');
        $('#white-cover').css('display', 'none');
      }, 1000); // you may need to adjust this timeout to match the duration of your hide animations

    }, 1000);

    sessionStorage.setItem('animationPlayed', 'true');
  } else {
    $('#circle-reveal').hide();
    $('#white-cover').hide();
  }
});

/**** Reveal animation end ****/